import { Config } from './types';

export const baseApiUri = process.env.REACT_APP_API_URL || 'https://inwf4fbyvm.eu-west-1.awsapprunner.com/api/v1';

export const config: Config = {
    name: process.env.REACT_APP_COMPANY_NAME || 'Flagstar',
    logoUrl: process.env.REACT_APP_COMPANY_LOGO || 'https://imagedelivery.net/YV7tiPr1pNu8eWcEhMhZuA/3e086a28-a458-4fe1-bc58-13151a47d700/public',
    primaryColor: process.env.REACT_APP_PRIMARY_COLOR || '#ffb81c',
    secondaryColor: process.env.REACT_APP_SECONDARY_COLOR || '#fff',
    thirdColor: process.env.REACT_APP_THIRD_COLOR || '#000',
};
